'use strict';

class Slajder {
  constructor() {
    (function($) {
      $(document).ready(() => {
        var t = check();
        var items = jQuery('.gallery .gallery_item');
        if (items.length > 1) {
          $('.container .gallery').owlCarousel({
            loop: false,
            nav: true,
            lazyLoad: false,
            smartSpeed: 1000,
            autoplay: false,
            rewind: false,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 2,
              },
              1024: {
                items: 2,
              },
              1280: {
                items: 3,
                nav: t,
              },
            },
            navText: ['<', '>']
          })
        }
      });
    })(jQuery);
  }
}

class Menu {
  constructor() {
    (function($) {
      $(document).ready(() => {
        $(window).scroll(menuClass);
      });
    })(jQuery);
  }
}

function check() {
  var items = jQuery('.gallery .gallery_item');
  if (items.length > 3) {
    return true;
  } else {
    return false;
  }
}

function menuClass() {
  var $menu = jQuery('.inv-top1');
  if (jQuery(window).scrollTop() > 150) {
    $menu.addClass('scrolled');
  }
  else {
    $menu.removeClass('scrolled');
  }
}

class VideoHeight {
  constructor() {
    (function($) {
      $(document).ready(() => {
        $(window).resize(function() {
          updateContainer();
        });
        $(window).onload = updateContainer();
      });
    })(jQuery);
  }
}

function updateContainer() {
  var vidh = jQuery('#bgvid').height();
  jQuery('#videofront .video').height(vidh);
  jQuery('#videofront .video__bg').height(vidh);
}

var videoh: VideoHeight = new VideoHeight();
var menutest: Menu = new Menu();
var slajder: Slajder = new Slajder();
